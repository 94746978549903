import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { ImageUrl } from '../../../Config';

const TechnicalSpecifications = () => {
  const [state, setState] = useState({});

  useEffect(() => {
    let techspcInsideSlider = InsideBannerList.filter((item) => item.title === 'technical-specs');
    setState(techspcInsideSlider[0]);
  }, []);

  return (
    <div>
      <Header/>
        <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
          <div className="container">
            <div className="row">
              <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
            </div>
          </div>
        </section>
        <section className="specifications py-5">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-2 col-lg-2 mx-auto">
                <div className="tech-spec">
                  <img src={ImageUrl+'images/tech-spec.png'} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-10 col-lg-10 mx-auto accordn">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Electrical Characteristics at Standard Test Conditions (STC)
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Module Type</td>
                              <td>VIL-320P</td>
                            </tr>
                            <tr>
                              <td>Maximum Power -Pmpp(W)</td>
                              <td>320</td>
                            </tr>
                            <tr>
                              <td>Positive Power Tolerance</td>
                              <td>0~2.5%</td>
                            </tr>
                            <tr>
                              <td>Open Circuit Voltage -Voc(V)</td>
                              <td>46.18</td>
                            </tr>
                            <tr>
                              <td>Short Circuit Current -Isc(A)</td>
                              <td>9.06</td>
                            </tr>
                            <tr>
                              <td>Maximum Power Voltage -Vmpp(V)</td>
                              <td>37.2</td>
                            </tr>
                            <tr>
                              <td>Maximum Power Current -lmpp(A)</td>
                              <td>8.61</td>
                            </tr>
                            <tr>
                              <td>Module Efficiency</td>
                              <td>Approx. 16%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Mechanical Characteristics
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Dimensions</td>
                              <td>1980 x 1010 x 14mm (Board Thickness)</td>
                            </tr>
                            <tr>
                              <td>Weight</td>
                              <td>44.0 Kg</td>
                            </tr>
                            <tr>
                              <td>Frame</td>
                              <td>Frame Less</td>
                            </tr>
                            <tr>
                              <td>Cells</td>
                              <td>Polycrystalline - 72 cells.</td>
                            </tr>
                            <tr>
                              <td>Junction Box</td>
                              <td>Rated current ≥ 15A,IP ≥ 67,TUV&amp;UL</td>
                            </tr>
                            <tr>
                              <td>Cable</td>
                              <td>Length 1000mm,1x4mm</td>
                            </tr>
                            <tr>
                              <td>Connector</td>
                              <td>MC4/compatible with MC 4</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Temperature Characteristics
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Voltage Temperature Coefficient</td>
                              <td>-0.300±0.024%/°C</td>
                            </tr>
                            <tr>
                              <td>Current Temperature Coefficient</td>
                              <td>0.059±0.007%/°C</td>
                            </tr>
                            <tr>
                              <td>Power Temperature Coefficient</td>
                              <td>-0.342±0.004%/°C</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Maximum Ratings
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Maximum System Voltage(V)</td>
                              <td>1000</td>
                            </tr>
                            <tr>
                              <td>Maximum Over-current Protection Rating</td>
                              <td>15A</td>
                            </tr>
                            <tr>
                              <td>Series Fuse Rating(A)</td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>Reverse Current Overload(A)</td>
                              <td>20</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        System Design
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Temperature Range</td>
                              <td>-40°Cto+85°C</td>
                            </tr>
                            <tr>
                              <td>Hail</td>
                              <td>Max.diameter of 25mm with 23m/s impact speed</td>
                            </tr>
                            <tr>
                              <td>Maximum Capacity</td>
                              <td>Snow 5400 Pa,wind 2400 Pa</td>
                            </tr>
                            <tr>
                              <td>Safety Application Class</td>
                              <td>Class A</td>
                            </tr>
                            <tr>
                              <td>Safety Class In Accordance With IS 61140</td>
                              <td>Class ll</td>
                            </tr>
                            <tr>
                              <td>Fire Safety Class</td>
                              <td>Class A</td>
                            </tr>
                            <tr>
                              <td>Recommended Maximum Series/parallel Module Conﬁgurations</td>
                              <td>Max 21 in series</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer/>
    </div>
  )
}

export default TechnicalSpecifications