import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { API_URL, CAMPAIGN_ID_ENQUIRY } from '../../../Config';

const Form = () => {
    const [stDst, setstDst] = useState([]);
    const [distData, setDistData] = useState([]);
    const navigate = useNavigate();

  // FORMIK FORM SUBMIT START 
  const formik = useFormik({
    initialValues: { name: '', email: '', phone: '', roof: '', total_load: '', area_roof: '', state: '', district: '', city: '', zipcode: '', address_property: '', hear_about: '', campaign_id: CAMPAIGN_ID_ENQUIRY},

    validate: (values) => {
      let errors = {};
      if (!values.name) {
        errors.name = 'Name is required';
      }
      if (!values.email) {
        errors.email = 'Email is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
      }
      if (!values.phone) {
        errors.phone = 'Mobile number is required';
      } else if ( !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.phone)) {
        errors.phone = 'Please enter numbers format only';
      }
      if (!values.roof) {
        errors.roof = 'Roof is required';
      }
      if (!values.total_load) {
        errors.total_load = 'Total load is required';
      }
      if (!values.area_roof) {
        errors.area_roof = 'Area of roof is required';
      }
      if (!values.state) {
        errors.state = 'State is required';
      }
      if(values.state){
        handleStateDistrict(values.state)
      }
      if (!values.district) {
        errors.district = 'District is required';
      }
      if (!values.city) {
        errors.city = 'City is required';
      }
      if (!values.zipcode) {
        errors.zipcode = 'Pin code required';
      }else if ( !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.zipcode)) {
        errors.zipcode = 'Please enter numbers format only';
      }
      if (!values.address_property) {
        errors.address_property = 'Message is required';
      }
      if (!values.hear_about) {
        errors.hear_about = 'This field is required';
      }
      return errors;
    },

    onSubmit:(values) => {
      const formData = new FormData();
      formData.append("name", values.name)
      formData.append("email", values.email)
      formData.append("phone_number", values.phone)
      formData.append("roof", values.roof)
      formData.append("total_load", values.total_load)
      formData.append("area_roof", values.area_roof)
      formData.append("state", values.state)
      formData.append("district", values.district)
      formData.append("city", values.city)
      formData.append("zipcode", values.zipcode)
      formData.append("address_property", values.address_property)
      formData.append("hear_about", values.hear_about)
      formData.append("category", "Item enquiry")
      formData.append("campaign_id", CAMPAIGN_ID_ENQUIRY)
      axios.post(`${API_URL}atum/enquiries/create`, formData)
      .then(response=>
        {
            // console.log(response)
            response.data.status === 200 ? navigate('/success') : navigate('/error') 
            formik.resetForm();
            // navigate('/success');
            // alert("Thank you for showing the interest an enquiry. Our Team will connect with you.");
        }
      )
      .catch(err=>console.log(err))
    }
  })
  // FORMIK FORM SUBMIT END 


    // STATE DISTRICT API INTIGRATION START
    const stateURL = "https://d2tm70q7nw2sv.cloudfront.net/assets/locations/json/indianStatesCities.json";
    const fetchStateDistrictInfo = async () => {
        const stateDistrictRes = await fetch(stateURL);
        const stateDistrictResData = await stateDistrictRes.json();
        setstDst(stateDistrictResData);
    }
    const handleStateDistrict = (e) => {
        // console.log(e);
        const getDistrict = stDst.filter((distitem) => distitem.State === e);
        setDistData(getDistrict);
    }
    // STATE DISTRICT API INTIGRATION END

    useEffect(() => {
        fetchStateDistrictInfo();
    }, [])

    return (
        <div>
            <section className="bg-white py-1">
                <div className="container">
                    <div className="row">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2">
                                    <input className="form-control" type="text" name="name" placeholder="Enter Name*" onChange={formik.handleChange} value={formik.values.name} />
                                    {formik.errors.name ? <span className="text-danger">{formik.errors.name}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2">
                                    <input className="form-control" type="text" name="email" placeholder="Enter Email*" onChange={formik.handleChange} value={formik.values.email} />
                                    {formik.errors.email ? <span className="text-danger">{formik.errors.email}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2">
                                    <input className="form-control" type="text" name="phone" placeholder="Enter Phone No.*" maxLength={10} onChange={formik.handleChange} value={formik.values.phone} />
                                    {formik.errors.phone ? <span className="text-danger">{formik.errors.phone}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 atc_select">
                                    <select className="form-select" name="roof" onChange={formik.handleChange} value={formik.values.roof}>
                                        <option value="" defaultValue>Select Type of Roof</option>
                                        <option value="Residential Apartment">Residential Apartment</option>
                                        <option value="Single house">Single house</option>
                                        <option value="Office">Office</option>
                                        <option value="Industrial plant">Industrial plant</option>
                                        <option value="Car Parking">Car Parking</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {formik.errors.roof ? <span className="text-danger">{formik.errors.roof}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 atc_select">
                                    <select className="form-select" name="total_load" onChange={formik.handleChange} value={formik.values.total_load}>
                                        <option value="" defaultValue>Total Load connected (in KW)</option>
                                        <option value="Lesser than 10KW">Lesser than 10KW</option>
                                        <option value="Between 10 KW and 20 KW">Between 10 KW and 20 KW</option>
                                        <option value="Between 20 KW and 30 KW">Between 20 KW and 30 KW</option>
                                        <option value="Between 30 KW and 40 KW">Between 30 KW and 40 KW</option>
                                        <option value="Greater than 40 KW">Greater than 40 KW</option>
                                    </select>
                                    {formik.errors.total_load ? <span className="text-danger">{formik.errors.total_load}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2">
                                    <input className="form-control" type="text" name="area_roof" placeholder="Enter Area of the roof (approx in SFT) *" onChange={formik.handleChange} value={formik.values.area_roof} />
                                    {formik.errors.area_roof ? <span className="text-danger">{formik.errors.area_roof}</span> : null}
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 atc_select">
                                    <select className="form-select" name="state" onChange={formik.handleChange} value={formik.values.state}>
                                        <option defaultValue>Select State*</option>
                                        {stDst && Array.from(new Set(stDst.map(obj => obj.State))).map((State, index) => {
                                            return (<option value={State} key={index}>{State}</option>)
                                        })}
                                    </select>
                                    {formik.errors.state ? <span className="text-danger">{formik.errors.state}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 atc_select">
                                    <select className="form-select" name="district" onChange={formik.handleChange} value={formik.values.district}>
                                        <option defaultValue>Select District*</option>
                                        {distData && distData.map((distObj, index) => {
                                            return (
                                                <option value={distObj.District} key={index}>{distObj.District}</option>
                                            )
                                        })}
                                    </select>
                                    {formik.errors.district ? <span className="text-danger">{formik.errors.district}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2">
                                    <input className="form-control" type="text" name="city" placeholder="City*" onChange={formik.handleChange} value={formik.values.city} />
                                    {formik.errors.city ? <span className="text-danger">{formik.errors.city}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2">
                                    <input className="form-control" type="text" name="zipcode" placeholder="Enter zipcode*" maxLength={6} onChange={formik.handleChange} value={formik.values.zipcode} />
                                    {formik.errors.zipcode ? <span className="text-danger">{formik.errors.zipcode}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2">
                                    <textarea className="form-control" name="address_property" placeholder="Enter message address of the property where ATUM is required" onChange={formik.handleChange} value={formik.values.address_property} rows="4"></textarea>
                                    {formik.errors.address_property ? <span className="text-danger">{formik.errors.address_property}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 atc_select">
                                    <select className="form-select" name="hear_about" onChange={formik.handleChange} value={formik.values.hear_about}>
                                        <option value="" defaultValue>How did you hear about us?</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="linkedin">linkedin</option>
                                        <option value="Twitter">Twitter</option>
                                        <option value="Online">Online</option>
                                        <option value="Airport Stall">Airport Stall</option>
                                        <option value="Hoardings">Hoardings</option>
                                        <option value="Auto Ads">Auto Ads</option>
                                        <option value="Newspaper/Magazine Ads">Newspaper/Magazine Ads</option>
                                    </select>
                                    {formik.errors.hear_about ? <span className="text-danger">{formik.errors.hear_about}</span> : null}
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 d-flex justify-content-center">
                                    <input type="submit" value="Submit" className="btn btn-danger fw-semibold global-primary-bg px-3" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Form