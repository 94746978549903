import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ImageUrl } from '../../../Config';

const Header = () => {
    const [isActive] = useState();
    return (
        <div id="sticky-header">
            <section  className="w-100 main-menu bg-white">
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-lg navbar-light website-menu-acive-line py-0">
                            <div className="container-fluid">
                                <Link className="navbar-brand" to="/">
                                    <div className="nav-logo-svg d-flex justify-content-between mx-auto">
                                        <img src={ImageUrl+'images/darklogo.png'} className="atum-logo" alt="atum-logo" />
                                    </div>
                                </Link>
                                <button className="navbar-toggler my-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                                    <ul className="navbar-nav mb-lg-0 my-4 my-lg-0 my-sm-4">
                                        <li className="nav-item">
                                            <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-3 lh-1" to="/" style={{isActive}}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-3 lh-1 about" to="/about" style={{isActive}}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-3 lh-1 portfolio" to="/portfolio" style={{isActive}}>Portfolio</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-3 lh-1 technicalspec" to="/technical-specs" style={{isActive}}>Technical Specs</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-3 lh-1 news" to="/news" style={{isActive}}>News & Events</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-3 lh-1 blog" to="/blog" style={{isActive}}>Blog</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-3 lh-1 contact" to="/contact" style={{isActive}}>Contact Us</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Header