import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import { ImageUrl } from '../../../Config';
import Header from '../common/Header';
import Footer from '../common/Footer';

const Portfolio = () => {
  const [state, setState] = useState({});

  useEffect(() => {
    let portfolioInsideSlider = InsideBannerList.filter((item) => item.title === 'portfolio');
    setState(portfolioInsideSlider[0]);
  }, []);

  return (
    <div>
      <Header/>
        <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
          <div className="container">
            <div className="row">
              <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="atum-different-wrapper atum-different-wrapper py-5">
              <div className="atum-different pb-5">
                <div className="row column-reverse overflow-hidden border rounded shadow align-items-center">
                  <div className="col-md-12 col-lg-6 col-12 p-0">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active"><img src={ImageUrl+'images/portfolio/un-habitat1.jpg'} className="d-block w-100" alt="Un Habitat" /></div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/un-habitat2.jpg'} className="d-block w-100" alt="Un Habitat" /></div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/un-habitat3.jpg'} className="d-block w-100" alt="Un Habitat" /></div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 ps-4 pt-4 product-hilights">
                    <h2 className="primary-text text-capitalize all-heading-text">UN Habitat</h2>
                    <p className="fs-7 text-gray"><i className="fal fa-map-marker-alt pe-1"></i>Nairobi, Kenya, East Africa</p>
                    <ul className="list-unstyled">
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">We have collaborated with Needlab to create a self-sustainable and a eco-friendly house at the UN-HABITAT assembly in Nairobi.</span> </li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">We strongly support the UN #sustainability development goals and believe that it is our duty to make #ecofriendly building materials and contribute to the #greenrevolution around the world. Cheers to all the Change makers! Come visit us at Kenya, UN Nairobi.
                      </span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="atum-different pb-5">
                <div className="">
                  <div className="row overflow-hidden rounded border shadow">
                    <div className="col-lg-6 col-12 ps-4 pt-4 product-hilights">
                      <h2 className="primary-text text-capitalize all-heading-text">Atum Factory</h2>
                      <p className="fs-7 text-gray"><i className="fal fa-map-marker-alt pe-1"></i>Miryalguda, Telangana, India</p>
                      <ul className="list-unstyled">
                        <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Capacity of The Plant: 275 kw
                        </span> </li>
                        <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Area Utilized: 19250 sft
                        </span></li>
                        <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Units Generated Per Annum: 3,79,500
                        </span></li>
                        <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Yearly Revenue (Lakhs): 26.6
                        </span></li>
                        <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Investment Pay Back Period: 5.2
                        </span></li>
                        <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Balance Life of plant: 19.8
                        </span></li>
                        <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Lifetime Earnings (Lakhs): 527
                        </span></li>
                      </ul>
                    </div>
                    <div className="col-md-12 col-lg-6 col-12 p-0">
                      <div id="carouselExampleIndicators2" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                          <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                          <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                          <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                          <div className="carousel-item active"><img src={ImageUrl+'images/portfolio/atum-factory1.jpg'} className="d-block w-100" alt="Atum Factory" /> </div>
                          <div className="carousel-item"><img src={ImageUrl+'images/portfolio/atum-factory2.jpg'} className="d-block w-100" alt="Atum Factory" /> </div>
                          <div className="carousel-item"><img src={ImageUrl+'images/portfolio/atum-factory3.jpg'} className="d-block w-100" alt="Atum Factory" /> </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="atum-different pb-5">
                <div className="row column-reverse overflow-hidden border rounded shadow align-items-center">
                  <div className="col-md-12 col-lg-6 col-12 p-0">
                    <div id="carouselExampleIndicators3" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active"> <img src={ImageUrl+'images/portfolio/airindia-ramp1.jpg'} className="d-block w-100" alt="Airindia Ramp" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/airindia-ramp2.jpg'} className="d-block w-100" alt="Airindia Ramp" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/airindia-ramp3.jpg'} className="d-block w-100" alt="Airindia Ramp" /> </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 ps-4 pt-4 product-hilights">
                    <h2 className="primary-text text-capitalize all-heading-text">AirIndia Ramp</h2>
                    <p className="fs-7 text-gray"><i className="fal fa-map-marker-alt pe-1"></i>Hyderabad, Telangana, India</p>
                    <ul className="list-unstyled">
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Capacity of the plant: 2.6kw</span>
                      </li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Area Utilized: 182 sft
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Units Generated Per Annum: 3588
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Yearly Revenue (Lakhs): 0.4
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Investment Pay Back Period: 3.0
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Balance Life of plant: 22.0
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Lifetime Earnings (Lakhs): 9
                      </span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="atum-different pb-5">
                <div className="row overflow-hidden rounded border shadow">
                  <div className="col-lg-6 col-12 ps-4 pt-4 product-hilights">
                    <h2 className="primary-text text-capitalize all-heading-text">AP CM Camp Office</h2>
                    <p className="fs-7 text-gray"><i className="fal fa-map-marker-alt pe-1"></i>Vijayawada, AP, India</p>
                    <ul className="list-unstyled">
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Capacity of the plant: 5.76 kw
                      </span> </li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Area Utilized: 403.2 sft
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Units Generated Per Annum: 7948.8
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Yearly Revenue (Lakhs): 0.55
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Investment Pay Back Period: 5.2
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Balance Life of plant: 19.82
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Lifetime Earnings (Lakhs): 11.03
                      </span></li>
                    </ul>
                  </div>
                  <div className="col-md-12 col-lg-6 col-12 p-0">
                    <div id="carouselExampleIndicators4" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active"> <img src={ImageUrl+'images/portfolio/ap-cm-camp-office1.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/ap-cm-camp-office2.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/ap-cm-camp-office3.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/ap-cm-camp-office4.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/ap-cm-camp-office5.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
                    </div>
                  </div>
                </div>
              </div>



              <div className="atum-different pb-5">
                <div className="row column-reverse overflow-hidden border rounded shadow align-items-center">
                  <div className="col-md-12 col-lg-6 col-12 p-0">
                    <div id="carouselExampleIndicators5" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators5" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators5" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators5" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active"> <img src={ImageUrl+'images/portfolio/cyber-towers-bustop1.jpg'} className="d-block w-100" alt="Cyber Towers Bustop" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/cyber-towers-bustop2.jpg'} className="d-block w-100" alt="Cyber Towers Bustop" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/cyber-towers-bustop3.jpg'} className="d-block w-100" alt="Cyber Towers Bustop" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/cyber-towers-bustop4.jpg'} className="d-block w-100" alt="Cyber Towers Bustop" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/cyber-towers-bustop5.jpg'} className="d-block w-100" alt="Cyber Towers Bustop" /> </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators5" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators5" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 ps-4 pt-4 product-hilights">
                    <h2 className="primary-text text-capitalize all-heading-text">Cyber Towers Bustop</h2>
                    <p className="fs-7 text-gray"><i className="fal fa-map-marker-alt pe-1"></i>Hyderabad, Telangana, India</p>
                    <ul className="list-unstyled">
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Capacity of the plant: 2.5 kw</span>
                      </li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Area Utilized: 175 sft
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Units Generated Per Annum: 3450
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Yearly Revenue (Lakhs): 0.2
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Investment Pay Back Period: 5.2
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Balance Life of plant: 19.8
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Lifetime Earnings (Lakhs): 5
                      </span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="atum-different pb-5">
                <div className="row overflow-hidden rounded border shadow">
                  <div className="col-lg-6 col-12 ps-4 pt-4 product-hilights">
                    <h2 className="primary-text text-capitalize all-heading-text">Intersolar Mumbai</h2>
                    <p className="fs-7 text-gray"><i className="fal fa-map-marker-alt pe-1"></i>Mumbai, Maharashtra, India</p>
                    <ul className="list-unstyled">
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">We are showcasing our most revolutionary product which is not just good looking but also environmentally friendly.
                      </span> </li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Witness India's first integrated solar roofing solution, ATUM - The Electric Roof at Stall no. 3224, Intersolar 2019, Mumbai from April 4th - 5th.
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">#SolarRoof #Renewable #Innovation #InterSolar2019
                      </span></li>
                    </ul>
                  </div>
                  <div className="col-md-12 col-lg-6 col-12 p-0">
                    <div id="carouselExampleIndicators6" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators6" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators6" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators6" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active"> <img src={ImageUrl+'images/portfolio/intersolar-mumbai1.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/intersolar-mumbai2.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/intersolar-mumbai3.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators6" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators6" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="atum-different pb-5">
                <div className="row column-reverse overflow-hidden border rounded shadow align-items-center">
                  <div className="col-md-12 col-lg-6 col-12 p-0">
                    <div id="carouselExampleIndicators7" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators7" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators7" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators7" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active"> <img src={ImageUrl+'images/portfolio/jaggatiyal-factory1.jpg'} className="d-block w-100" alt="Cyber Towers Bustop" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/jaggatiyal-factory1.jpg'} className="d-block w-100" alt="Cyber Towers Bustop" /> </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators7" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators7" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 ps-4 pt-4 product-hilights">
                    <h2 className="primary-text text-capitalize all-heading-text">Jaggatiyal Factory</h2>
                    <p className="fs-7 text-gray"><i className="fal fa-map-marker-alt pe-1"></i>Jagityal, Telangana, India</p>
                    <ul className="list-unstyled">
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Capacity of the plant: 200 kw</span>
                      </li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Area Utilized: 14000 sft
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Units Generated Per Annum: 2,76,000
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Yearly Revenue (Lakhs): 19.3
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Investment Pay Back Period: 5.2
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Balance Life of plant: 19.8
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Lifetime Earnings (Lakhs): 383
                      </span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="atum-different pb-5">
                <div className="row overflow-hidden rounded border shadow">
                  <div className="col-lg-6 col-12 ps-4 pt-4 product-hilights">
                    <h2 className="primary-text text-capitalize all-heading-text">V6 News</h2>
                    <p className="fs-7 text-gray"><i className="fal fa-map-marker-alt pe-1"></i>Hyderabad, Telangana, India</p>
                    <ul className="list-unstyled">
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Capacity of the plant: 8 kw</span>
                      </li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Area Utilized: 560 sft
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Units Generated Per Annum: 11040
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Yearly Revenue (Lakhs): 1.1
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Investment Pay Back Period: 3.6
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Balance Life of plant: 21.4
                      </span></li>
                      <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Lifetime Earnings (Lakhs): 24
                      </span></li>
                    </ul>
                  </div>
                  <div className="col-md-12 col-lg-6 col-12 p-0">
                    <div id="carouselExampleIndicators8" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators8" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators8" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators8" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active"> <img src={ImageUrl+'images/portfolio/v6-news1.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/v6-news2.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                        <div className="carousel-item"> <img src={ImageUrl+'images/portfolio/v6-news3.jpg'} className="d-block w-100" alt="AP CM Camp Office" /> </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators8" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators8" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      <Footer/>
    </div>
  )
}

export default Portfolio