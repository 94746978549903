import React from 'react';
import { Link } from 'react-router-dom';
import Form from '../pages/Form';

const Footer = () => {
    return (
        <div>
            <section className="reachUs primary-bg py-3 text-white">
                <div className="container">
                    <div className="row">
                        <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-between align-items-center">
                            <div className="d-flex justify-content-center">
                                <h2 className="fw-semibold text-justify fs-5 m-0 p-0 lh-base">You can reach us at +91 91006 82886 We'll get back to you at the earliest.</h2>
                            </div>
                            <div className="d-flex justify-content-center pt-3 pt-sm-3 pt-md-0 pt-lg-0 pt-xl-0">
                                <a href="https://d1y6735g8zh2n9.cloudfront.net/assets/images/atum_brochure.pdf" className="px-3 py-2 rounded-5 fw-normal text-white border border-2 border-white text-decoration-none" target="_blank" rel="noopener noreferrer">Download Brochure</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="text-white pt-5">
                <div className="container">
                    <div className="row pb-lg-4 border-bottom border-dark">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-3 pb-3 pb-lg-0 d-flex flex-column justify-content-center align-items-center align-items-sm-center align-items-md-start align-items-lg-start align-items-xl-start">
                            <div className="atum-logo">
                                <div className="atum-logo-svg d-flex justify-content-between me-auto">
                                    <div className="svg svg-a">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.05 61.77">
                                            <g id="A">
                                                <path fill="var(--common_white_50)"
                                                    d="M61.41,61.22H54.16l-7-15.77H14.68L7.6,61.22H.7L27.9,1.11h6.31ZM31,9,17.32,39.4H44.53Z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="svg svg-t">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.16 61.77">
                                            <g id="T">
                                                <path fill="var(--common_white_50)" d="M28,60.88H21.15V7.41h-20V1.19H48V7.41H28Z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="svg svg-u">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.44 61.77">
                                            <g id="U">
                                                <path fill="var(--primary_color)"
                                                    d="M57.87,3.35,57.45,3,55.39,1V9.89l.23.29A27,27,0,0,1,61.48,27,27.29,27.29,0,0,1,6.9,27a27,27,0,0,1,5.85-16.86L13,9.88V1L10.91,3c-.14.12-.27.25-.4.38a33.46,33.46,0,0,0,0,47.36A33.49,33.49,0,0,0,67.68,27,33.31,33.31,0,0,0,57.87,3.35Z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="svg svg-m">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="M" viewBox="0 0 58.67 61.77">
                                            <path fill="var(--common_white_50)"
                                                d="M29.45,44.36h-.34L7.37,12.47V60.73H.8V1H7.62L29.37,33.61,51.11,1h6.82V60.73H51.2V12.38Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <span className="pt-4 pb-2 fw-lighter text-white">A breakthrough invention in the field of sustainable energy, Atum is an Integrated Solar
                                Rooﬁng System that serves all the functions of a traditional roof while generating energy for you.
                            </span>
                            <Link to="/about" className="text-capitalize primary-text text-decoration-none">read more..</Link>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 pb-3 pb-lg-0 d-flex flex-column align-items-center align-items-sm-center align-items-md-start align-items-lg-start align-items-xl-start">
                            <div className="footer-links">
                                <h5 className="mb-3">Useful Links</h5>
                            </div>
                            <ul className="list-unstyled">
                                <li className="mb-2"><Link to="/portfolio" className="text-white"><span className="text-white icon-font pe-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="5px" fill="#fff"><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg></span> <span className="text-white">Portfolio</span></Link></li>
                                <li className="mb-2"><Link to="/technical-specs" className="text-white"><span className="text-white icon-font pe-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="5px" fill="#fff"><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg></span> <span className="text-white">Technical Spec</span></Link></li>
                                <li className="mb-2"><Link to="/news" className="text-white"><span className="text-white icon-font pe-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="5px" fill="#fff"><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg></span> <span className="text-white">News & Events</span></Link></li>
                                <li className="mb-2"><Link to="/blog" className="text-white"><span className="text-white icon-font pe-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="5px" fill="#fff"><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg></span> <span className="text-white">Blog</span></Link></li>
                                <li className="mb-2"><Link to="/contact" className="text-white"><span className="text-white icon-font pe-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="5px" fill="#fff"><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg></span> <span className="text-white">Contact Us</span></Link></li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-2 pb-3 pb-lg-0 d-flex flex-column align-items-center align-items-sm-center align-items-md-start align-items-lg-start align-items-xl-start">
                            <div className="footer-links">
                                <h5 className="mb-3">Follow Us</h5>
                            </div>
                            <ul className="list-unstyled">
                                <li className="mb-2">
                                    <a className="share-facebook d-flex align-items-center" href="https://www.facebook.com/atumroof"
                                        target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512" fill="var(--common_white_50)" className="pe-2"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg><span className="text-white"> Facebook</span></a>
                                </li>
                                <li className="mb-2">
                                    <a className="share-instagram d-flex align-items-center" href="https://twitter.com/atumroof" target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="var(--common_white_50)" className="pe-2"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg>
                                        <span className="text-white">Twitter</span>
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="share-instagram d-flex align-items-center" href="https://www.instagram.com/atumroof/" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="var(--common_white_50)" className="pe-2"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg> <span className="text-white">Instagram</span></a>
                                </li>
                                <li className="mb-2">
                                    <a className="share-linkedin d-flex align-items-center"
                                        href="https://www.linkedin.com/company/atumsolar/" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="var(--common_white_50)" className="pe-2"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg> <span className="text-white">Linkedin</span></a>
                                </li>
                                <li className="mb-2">
                                    <a className="share-youtube d-flex align-items-center"
                                        href="https://www.youtube.com/channel/UCGB2vfn29Yd1iRFNzB4tHnQ" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="var(--common_white_50)" className="pe-2"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" /></svg>
                                        <span className="text-white">Youtube</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 pb-3 pb-lg-0 d-flex flex-column align-items-center align-items-sm-center align-items-md-start align-items-lg-start align-items-xl-start">
                            <div className="footer-links">
                                <h5 className="mb-3">Contact Us</h5>
                            </div>
                            <ul className="list-unstyled d-flex flex-column align-items-center align-items-sm-center align-items-md-start align-items-lg-start align-items-xl-start">
                                <li className="d-flex align-items-center mb-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 512 512" fill="var(--common_white_50)" className="pe-2"><path d="M280 0V24 65.5C366.8 76.3 435.7 145.2 446.5 232H488h24v48H488 446.5C435.7 366.8 366.8 435.7 280 446.5V488v24H232V488 446.5C145.2 435.7 76.3 366.8 65.5 280H24 0V232H24 65.5C76.3 145.2 145.2 76.3 232 65.5V24 0h48zM112 256a144 144 0 1 0 288 0 144 144 0 1 0 -288 0zm192 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-144 0a96 96 0 1 1 192 0 96 96 0 1 1 -192 0z" /></svg>
                                    <span className="text-white">Visaka Industries, 1-8-303/69/3, Visaka Tower, S P Road, Secunderabad, India, Telangana - 500003.</span>
                                </li>
                                <li className="phone mt-3 mb-2"><a href="tel:+919100682886" className=" d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" fill="var(--common_white_50)" className="pe-2"><path d="M80 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H80zM16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM192 392a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg><span className="text-white">+91 91006 82886</span></a></li>
                                <li className="mb-2"><a href="mailto:atum@visaka.in" className=" d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="var(--common_white_50)" className="pe-2"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" /></svg><span className="text-white">atum@visaka.in</span></a></li>
                                <li className="mb-2"><a href="/" className=" d-flex align-items-center" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="var(--common_white_50)" className="pe-2"><path d="M303.6 415.6C283 456.8 263.4 464 256 464c-3.1 0-8.3-1.3-14.8-5.7l-15.3 52c9.9 1.2 19.9 1.8 30.1 1.8c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256c0 10.2 .6 20.2 1.8 30.1l46.9-13.8c-.4-5.4-.6-10.8-.6-16.3c0-16.5 1.9-32.6 5.6-48h76.7c-1.3 13.3-2 27-2.2 41l48.4-14.2c.4-9.1 1.1-18 2-26.7h155c1.6 15.3 2.5 31.4 2.5 48s-.9 32.7-2.5 48h-47l-14.1 48h53.2c-5.6 24.4-13.2 45.9-22 63.6zM208.4 96.4C229 55.2 248.6 48 256 48s27 7.2 47.6 48.4c8.8 17.7 16.4 39.2 22 63.6H186.4c5.6-24.4 13.2-45.9 22-63.6zM384 256c0-16.4-.8-32.5-2.2-48h76.7c3.6 15.4 5.6 31.5 5.6 48s-1.9 32.6-5.6 48H381.8c1.5-15.5 2.2-31.6 2.2-48zm-9.3-96c-7.3-35.9-18.5-67.7-32.6-93.4c42.3 19.3 77 52.3 98.4 93.4H374.7zM71.4 160c21.4-41.1 56.1-74.1 98.4-93.4c-14.1 25.6-25.3 57.5-32.6 93.4H71.4zM374.7 352h65.9c-21.4 41.1-56.1 74.1-98.4 93.4c14.1-25.6 25.3-57.5 32.6-93.4zM39 308.5c-13.9 4.1-15.6 23.2-2.6 29.7l57.3 28.7c1.3 .7 2.6 1.5 3.7 2.5l-88 88c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l88-88c1 1.1 1.9 2.3 2.5 3.7l28.7 57.3c6.5 13 25.6 11.3 29.7-2.6l60.2-204.8c3.6-12.1-7.7-23.4-19.9-19.9L39 308.5z" /></svg><span className="text-white">www.atum.in</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom py-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-between">
                                <ul className="list-unstyled d-flex justify-content-center justify-content-sm-center justify-content-md-start justify-content-lg-start justify-content-xl-start py-2 m-0">
                                    <li><span className="text-white">Copyrights &copy;<Link to="/" className="primary-text text-decoration-none px-1">Atum</Link>2023. All Rights Reserved. </span></li>
                                </ul>
                                <ul className="list-unstyled d-flex justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row align-items-center py-2 m-0">
                                    <li className="d-inline"><Link to="/terms-conditions">Terms & Conditions <span className="px-2">||</span></Link> </li>
                                    <li className="d-inline"><Link to="/privacy-policy">Privacy & Policy <span className="px-2">||</span></Link></li>
                                    <li className="d-inline"><Link to="/cookie-policy">Cookie Policy </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <section className="side-canvas">
                <button className="btn btn-danger primary-bg py-2 px-4 rounded-top rounded-0 position-fixed bottom-0 end-0 fw-normal" type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Enquiry Now
                </button>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header bg-gray">
                        <h5 id="offcanvasRightLabel">Enquiry Now</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body m-0 p-2">
                        <Form/>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer